*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  z-index: 222;
  height: 18px;
  border-radius: 20%;
  background: #000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}










.loading{
 padding-left: 47%;
  font-size: 3vw;
  text-align: center;
  padding-top: 50vh;
}
.jokesbutton{
  padding: 15px;
  display: flex;
  justify-content: center;
}
.jokesbutton button{
  margin-top: 13px;
  font-family: Barlow;
  font-size: 15px;
  font-weight: bold;
  width: 120px;
  border-radius: 15px;
  height: 32px;
  border: none;
}
.joke{
  text-align: center;
    font-family: 'Poppins';
        font-size: 24px;
    padding: 20px;
    margin-top: 20px;
}
/* .Slxdj{
  color: #f8f4f4 !important;
} */
.cVXxbE{
  background-color: #2f2f32 !important;
  box-shadow: 0 0 1px 3px rgb(240 240 240) !important;
}
/* .ezKHIn:hover{
  box-shadow: 0 0 1px 3px rgb(240 240 240);
} */
.switch{
    margin-left: 30px;
}
#light{
    background-color: white;
}
#dark{
    background-color: #212125 !important;
}
#dark .caro{
  background-color: #212125 !important;
}
#dark .kXteup, .Slxdj{
  color: white !important;
}
#dark .upper{
    background-color: #212125;
}
#dark #Home img{
    filter: brightness(0.5);
}
#dark .btu{
  color: black;
}
#dark .fl{
    background-color: #fff;
    color: #000;
    z-index: 1;
}
#dark .fl a h2{
    color: #000;
}
#dark .section1 p{
    color: white;
}
#dark .wholesection{
    color: white;
}
#dark hr{
    color: white;
}
#dark .wwd h1{
    color: white;
}
#dark .box h1{
    color: #2f2f32;
}
#dark .notCarton{
    background-image: none;
}
#dark .notCarton h1{
    color: white;
}
#dark .notCarton p{
    color: white;
}
#dark .clientheading{
  color: #fff;
}
#dark .team{
  color: #fff;
}
#dark .mtd p{
  color: #fff;
}
#dark .box{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 272px;
    height: 285px;
    color: #2f6e91;
    background: #f0f0f0;
    animation: animeb 3s cubic-bezier(0.16, 1, 0.3, 1) 1s infinite alternate;
}
@keyframes animeb {
    0% {
        font-size: 20px;
      width: 272px;
      height: 285px;
      background: #f0f0f0;
    box-shadow:  none
  }
    25% {
      width: 272px;
      height: 285px;
      background: #f8f8f8;
      box-shadow:  none
    }
    50% {
      width: 272px;
      height: 585px;
      background: #f8f8f8;
      box-shadow:  none
    }
    100% {
        font-size: 25px;
      width: 272px;
      height: 585px;
      background: #fafafa;
                   box-shadow:  none
    }
  }
  #dark .box p{
    font-family: Heebo;
    padding: 15px;
    color: #2f2f32;
    text-align: center;
    font-size: 15px;
    animation: eee 3s cubic-bezier(0.16, 1, 0.3, 1) 1s infinite alternate;
  }
  @keyframes eee {
    0%{
      opacity: 0;
    }
    25%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }
  #dark .one-drop{
    background-color: black;
  }
  #dark .two-drop{
    background-color: black;
  }
  /* #dark .teamfaces .teamf{
    background-color: #fff;
    color: #000;
  } */
  #dark .footer-all{
    background-color: #f0f0f0;
  }
  #dark .footer-div h1{
    color: #000;
  }
@media only screen and (max-width: 767px){
   .switch{
    transform: scale(0.5);
    margin-left: 0;
   }
  .loading{
    padding-left: 35vw;
    padding-top: 20vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1211px){
    @keyframes animeb {
        0% {
      width: 203px;
      height: 470px;
      background: #f0f0f0;
      box-shadow: none;
    }
    25% {
      width: 203px;
      height: 470px;
      background: #f8f8f8;
      box-shadow:  none;
    }
        50% {
            width: 203px;
            height: 800px;
            background: #f8f8f8;
            box-shadow:  none;
          }
          100% {
            width: 203px;
            height: 800px;
            background: #fafafa;
            box-shadow:  none;
          }
    }
}

