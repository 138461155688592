*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  /* Picontent css */
  .all{
      padding: 20px;
      display: flex;
      margin-bottom: 65px; 
      justify-content: left;
  }
  .menupage{
    display: flex;
    background-image: url('./iconbackground7.jpg');
    justify-content: space-between;
    background-size: cover;
    padding: 15% 9% 9% 9%;
  }
  .menupage h1{
    color: white;
    font-family: poppins;
    font-size: 3rem;
  }
  .dashh{
    padding: 0 25px 0 25px;
  }
  .mp2{
    display: flex;
    font-family: poppins;
    font-size: 12px;
    color: white;
  }
  .abthome{
    color: white;
    text-decoration: none;
  }
  .abthome:hover{
    color: #c40a0c;
    transition: all 1s;
  }
  .mp2 .abt{
    color: #b3b0ad;
  }
  .teamfaces{
    display: flex;
    justify-content: space-between;
    
  }
  .teamfaces .teamf{
    width: 24%;;
    height: 100%;
    border-radius: 15px;
    border-style: solid;
    border: 0.1;
    border-width: 1px;
    border-color: #E7E5EA;
  }
  .team{
      margin: 50px 9% 0 9%;
  }
  .teampic{
    display: flex;
    justify-content: center;
  }
  .teampic img{
    clip-path: circle();
    width: 70%;
    margin: 30px 0 30px 0;
  }
  .teamf h1{
    font-size: 20px;
    font-family: poppins;
    font-weight: 600;
    text-align: center
  }
  .teamf p{
    font-size: 18px;
    font-family: poppins;
    text-align: center;
    padding-bottom: 30px;
  }
  .mtd h1{
    font-family: poppins;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .mtd p{
    color: #626161;
    font-family: poppins;
    font-size: 19px;
    width: 53%;
    margin-bottom: 50px;
  }
  .aboutuspage{
    margin-top: 50px;
  }
  .all img{
      width: 27%;
      margin-left: 70px;
      border-radius: 10px;
  }
  .notCarton{
    padding: 75px 40px 0 40px;
    background-image: url('./line1.png');
    width: 100%;
    /* box-shadow: 1px 2px 13px 0px rgb(0 0 0 / 20%); */
  }
  hr{
      width: 8.9vw;
      margin-top: 25px;
      margin-left: 9px;
      border-style: solid;
      color: #27314f
  }
  .notCarton h1{
      font-size: 2.65vw;
      font-family: 'Barlow';
      color: #27314f
  }
  .notCarton p{
    margin-top: 20px;
    color: #626161;
    font-size: 1.40vw;
    width: 84%;
    font-family: Heebo, sans-serif;
  }
  
  
  
  @media only screen and (max-width: 767px){
    /* Picontent css */
    .menupage{
      padding: 27% 9% 10% 9%;
      flex-direction: column;
    }
    .secondid{
      margin-top: 10px;
    }
    .menupage h1{
      font-size: 1.5em;
    }
    .mp2{
      width: 100%;
    }
    .dashh{
      padding-left: 8px;
      padding-right: 8px;
    }
    .all{
      flex-direction: column;
      margin-top: 0;
    }
    .secondio{
      padding-top: 28%;
    }
    hr{
      margin-left: 5px;
      width: 19.9vw;
    }
    .all img{
      margin-left:0px;
      width: 100%;
    }
    .notCarton{
      width: 100%;
      padding: 20px 0 0 0 !important;
      padding: 20px;
      margin-right: 100px;
    }
    .notCarton h1{
      font-size: 6.35vw;
    }
    .notCarton p{
      font-size: 5vw;
      width: 100%
    }
    .mtd h1{
      padding: 0 10px 0 10px;
    }
    .mtd p{
      width: 100%;
      font-size: 3.8vw;
      padding: 0 10px 0 10px;
    }
    .firstimg{
      opacity: 1 !important;
    }
    .team{
      margin: 50px 2% 0 2%;
    }
    .teamf h1{
      font-size: 6.4vw;
    }
    .teamf p{
      font-size: 6.4vw;
    }
    .teamfaces .teamf{
      width: 100%;
      margin-bottom: 10px;
    }
    .teamfaces{
      flex-direction: column;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .menupage{
      padding: 17% 9% 9% 9%;
    }
    .all img{
      margin-left: 10px;
      width: 31%;
    }
    .secondio{
      padding-top: 18%;
    }
    .notCarton{
      width: 70%;
      padding: 25px;
    }
    .notCarton p{
      font-size: 1.80vw;
      width: 100%;
    }
    .mtd p{
      width: 100%; 
    }
    .teamfaces .teamf{
      width: 100%;
    }
    .teamfaces{
      display: grid;
      justify-content: center;
  grid-gap: 1rem;
  grid-template-areas: "item item2";
    }
    .firstimg, .secondimgg, .thirdimgg, .fourthimgg{
      opacity: 1 !important;
    }
  }