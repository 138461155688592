*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .allheader1{
    position: fixed;
    width: 100%;
    z-index: 2;
  }
.allheader{
    background-color: #212125;
    width: 100%;
}
.phoneview{
    display: none;
}
.upper{
    /* background-color: #2d2f30; */
    background-color: #2f2f32;
    /* background-color: #2f6e91; */
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px 50px 3px 0;
    justify-content: right;
}
.upper p{
    font-family: abel;
    font-size: 13px;
    margin: 8px 8px 8px 0;
    color: white;
}
.FaCaretDown{
    font-size: 18px;
    position: absolute;
    color: #ffffff9e;
}
.wholeflex{
    background-color: #212125;
}
.headerflex{
    display: flex;
    justify-content: space-between;
    padding: 0px 60px 5px 60px;
}
.dropmenu{
    display: none;
}
.logo{
    margin-top: 1px;
    width: 55%;
}
.menuoptflex .opt{
    text-decoration: none;
}
.menuoptflex .opt{
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 13px;
    margin-top: 22px;
    margin-bottom: 7px;
    letter-spacing: 1px;
    font-family: poppins;
    text-transform: uppercase;
}
.menuoptflex .opt:hover{
    color: #ffffff;
    border-style: solid;
    border-width: 3px;
    border-left: none;
    border-top: none;
    border-right: none;
}
.menuoptflex{
    
    display: flex;
    justify-content: space-around;
    width: 35%;  
}
.ctb {
    margin-top: 13px;
    font-family: Barlow;
    font-size: 13px;
    font-weight: bold;
    width: 96px;
    border-radius: 15px;
    height: 32px;
    border: none;
}
.ctb:hover{
    transition: all 1s;
    color: #fff;
    background-color: #2f6e91;
}
.drop-down a{
    color: #fff;
    text-decoration: none;
}
.drop-down a:hover{
    color: #c60b0e;
    transition: all .1s linear;
 }
 .one-drop, .two-drop{
    /* display: none; */
    background-color:#2f6e91;
    padding: 15px;
    line-height: 30px;
    position: absolute;
    margin-top: 20px;
    margin-left: -10px;
    font-weight: lighter;
 }
@media only screen and (max-width: 767px){
    .upper{
        padding: 0;
        display: flex;
        justify-content: center;
        padding-left: 15px;
    }
    .upper p{
        font-size: 2.54vw;
        margin: 9px 5px 0 0;
    }
    .wholeflex{
        position: absolute;
        margin-top: -1px;
        width: 100%;
        z-index: 2;
        transition: 1s;
        transform: translateX(-200vh);
    }
    .navclick{
        transform: none;
    }
    .headerflex{
        flex-direction: column;
    }
    .headerflex .opt{
        font-size: 13px;
    }
    .logo{
        display: none;
    }
    .menuoptflex{
        flex-direction: column;
        width: 100%;
    }
    .menuoptflex .opt{
        text-align: center;
    }
    .menuoptflex .opt:hover{
        color: #ffffff;
        font-size: 13px;
        border-style: none;
    }
    .contbtn{
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
    .phoneview{
        display: flex;
        justify-content: space-between;
        margin: 0 10px 0 10px;
    }
    .logoview img{
        width: 58%;
    }
    .dropmenu{
        display: block;
        margin: 7px 0 0 0;
        color: white;
    }
    .testing{
        margin-top: 0;
        display: flex;
        justify-content: center;
    }
    .drop-down a{
        color: #2f6e91;
    }
    .one-drop, .two-drop{
        position: relative;
        margin-left: 0;
        background-color: #fff;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .upper{
        padding: 8px 11px 3px 0;
    }
    .logo{
        width: 25%;
    }
    .logo img{
        width: 85%;
    }
    .menuoptflex{
        width: 55%;
    }
    .headerflex{
        padding: 10px 30px 10px 20px;
    }
    .menuoptflex .opt{
        margin-top: 15px;
    }
    .ctb{
        margin-top: 2px;
        width: 105px;
        height: 40px;
        font-size: 15px;
    }
}