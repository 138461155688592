*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.wholesection{
    margin-top: 58px;
    margin-bottom: 145px;
    padding: 40px 0 20px 0;
    display: flex;
    justify-content: space-around;
}
.allsection{
    width: 70%;
}
.wwd{
    margin-left: 60px;
}
.wwd h1{
    font-family: barlow;
    color: #2f6e91;
    font-size: 2.65vw;
}
.serviceshr{
    width: 6vw;
    color: #2f6e91;
}
.sectionflex{
    display: flex;
    justify-content: space-around;
}
/* .sectionflex .section1{
    width: 43%;
} */
.newwithforsection1sunstitute{
    width: 43%;
}
.build1{
    margin-top: 90px;
}
.payment1{
    margin-top: 107px;
}
.section1 .buildimg{
    width: 20%;
    margin-left: -28px;
}
.section1 .paymentimg{
    width: 9%;
    margin-top: -7px;
}
.section1 .consultantimg{
    width: 10%;
}
.section1 .managementimg{
    width: 10%;
    margin-top: -4px;
}
.section1 h1{
    font-size: 20px;
    margin-top: 6px;
    padding-bottom: 25px;
    font-family: Barlow, sans-serif;
}
.section1 p{
    font-family: Heebo;
    color: #000000bd;
    font-size: 15px;
}

@media only screen and (max-width: 767px){
    .div1opacity{
        opacity: 1 !important;
        margin-top: 600px;
    }
    .wholesection, .sectionflex{
        flex-direction: column;
    }
    .wholesection{
        padding: 25px;
        margin-bottom: 60px;
    }
    .wwd h1 {
        font-size: 6.35vw;
    }
    .wwd {
        margin-left: 0px;
    }
    .serviceshr {
        width: 22vw;     
    }
    .newwithforsection1sunstitute{
        width: 100%;
    }
    .sectionflex .section1, .allsection{
        width: 100%;
        margin-top: 10px;
    }
    .build1 {
        margin-top: 0px;
    }
    .section1 .buildimg {
        width: 35%;
        margin-left: -41px;
    }
    .section1 .paymentimg {
        width: 17%;
    }
    .section1 .consultantimg{
        width: 18%;
    }
    .section1 .managementimg {
        width: 18%;
    }
    .payment1, .consultant1, .management1{
        margin-top: 40px !important;
    }
    .payment1 h1{
        margin-top: 10px;
    }
    .consultant1 h1{
        margin-top: 10px;
    }
    .management1 h1{
        margin-top: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .div1opacity{
        opacity: 1 !important;
    }
    .div2opacity{
        opacity: 1 !important;
    }
    .section1 .buildimg {
        width: 25%;
        margin-left: -29px;
    }
    .section1 .paymentimg {
        margin-top: 5px;
    }
    .wwd{
        margin-left: -20px;
    }
    .newwithforsection1sunstitute {
        width: 47%;
    }
    .payment1 {
        margin-top: 96px;
    }
    .management1{
        margin-top: 104px !important;
    }
}