*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .cll{
    position: absolute;
      color: white;
      /* margin: 28% 0 0 0%; */
      margin: 21% 0 0 0 ;
      z-index: 1;
      background-color: #00000075;
      width: 100%;
      color:white;
      padding: 60px 30px 60px 30px;
      font-family: Poppins;
      animation-name: cll;
      animation-duration: 2s;
  }
  
  .cll h1{
      font-size: 5.5vw;
      font-weight: 900;
      font-family: abel;
  }
  .cll p{
      font-size: 3vw;
      font-family: abel;
      width: 70%;
  }
  .btu{
      width: 140px;
      height: 50px;
      margin-top: 10px;
      font-size: 16px;
      background-color: white;
      font-family: poppins;
      font-weight: bold;
      color: #2f6e91;
      border-radius: 34px;
      border-style: solid;
      border-color: white;
      border-width: 4px;
  }
  .btu:hover{
    background-color: #457d9d;
      color: white;
      border-color: #457d9d;
      transition: all 1s;
  }
  @media only screen and (max-width: 767px){
    /* Section 1 css */
    .cll{
      padding: 10px;
      margin: 36% 0 0 0%;
    }
    #Home img{
      margin-top: 58px;
    }
    .btu{
      width: 25%;
      height: 10%;
      font-size: 3.04vw;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
  #Home img{
    margin-top: 80px;
  }
  .cll{
    margin: 26% 0 0 0%;
  }
}