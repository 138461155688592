*{
    margin:0;
    padding:0;
    margin: 0;
  }
  .error{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 65px;
  }
  .error img{
    width: 100%;
  }
  .error button{
    height: 40px;
    width: 100%;
    background-color: #2f2d41;
    font-size: 15px;
    color: white;
    font-family: poppins;
    border: none;
    margin-bottom: 20px;
  }
  button:hover{
    background-color: #4d4b5c;
    transition: all 1s;
  }