*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.group{
    display: flex;
    justify-content: space-evenly;
    margin-top: -80px;
    
}
.fl{
    z-index: 1;
    border-radius: 8px;
    width: 27%;
    height: 100%;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    background-color: white;
}
.mb{
    border-radius: 8px;
    margin-top: -45px;
    width: 27%;
    height: 100%;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    background-color: #72c2ee;
}
.image1, .image2, .image3{
    margin-top: 60px;
    display: flex;
    justify-content: center;
}
.image1 img{
    width: 30%;
}
.image2 img{
    width: 30%;
    margin-top: 45px;
}
.image3 img{
    width: 30%;
}
.fl h1{
    margin-top: 30px;
    text-align: center;
    font-size: 1.831vw;
    font-family: Inter, sans-serif;
}
.fl p{
    padding: 15px 50px 15px 50px;
    font-family: Heebo, sans-serif;
    font-size: 1.22vw;
    text-align: center;
}
.fl a{
    text-decoration: none;
    text-align: center;
    color: #2f72a2;
    font-family: Barlow;
    font-size: 12px;
}
a h2{
    margin-bottom: 35px;
}
.arrowright{
    font-size: 15px;
    height: 10px;
    margin-left: 3px;
}
@media only screen and (max-width: 767px){
    .group{
        flex-direction: column;
        margin-top: 0;
    }
    .mb, .fl{
        margin-bottom: 10px;
        width: 100%;
        margin-top: 0;
    }
    .fl h1{
        font-size: 24px;
    }
    .fl p{
        font-size: 16px;
        padding: 15px 25px 15px 25px;
    }
    .fl a{
        font-size: 12px;
    }
    .arrowright{
        height: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1211px){
    .group{
        margin-top:-35px;
    }
    .image2 img{
        margin-top: 25px;
    }
    .fl p{
        padding: -15px;
    }
    .mb{
        margin-top: -25px;
    }
    .arrowright{
        height: 10px;
    }
}