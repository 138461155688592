*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.divwithfooterdesign{
    position: absolute;
    width: 25%;
    opacity: 0.5;
}
.footer-all{
    padding: 120px 120px 50px 120px;
    background-color: #7db9db6b;
}
.footer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
}
.footer-div{
    z-index: 1;
}
.footer-div h1{
    font-family: poppins;
    font-weight: 900;
    margin-bottom: 5px;
    font-size: 25px;
    line-height: 25.88px;
    color: #000000;
}
.footer-div p{
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    opacity: 60%;
}
.last-footer{
    display: flex;
    justify-content: space-between;
}
.last-footer p{
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    opacity: 60%;
}
.social{
    display: flex;
    justify-content: space-around;
    width: 18%;
}
.social a{
    width: 20%;
}
.social .Facebook{
    width: 83%;
}
.social .Instagram{
    width: 80%;
}
.social .Linkedin{
    width: 80%;
}
@media only screen and (max-width: 767px){
    .divwithfooterdesign{
        width: 36%;
    }
    .footer-all{
        padding: 20px;
    }
    .footer{
        flex-direction: column;
        margin-bottom: 50px;
    }
    .footer-div h1{
        margin-top: 35px;
        font-size: 20px;
    }
    .footer-div p{
        font-size: 13px;
}
    .last-footer p{
        font-size: 13px;
    }
    .last-footer{
       flex-direction: column;
    }
    .social{
        width: 40%;
        margin-top: 30px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .footer-all{
        padding: 70px 50px 50px 50px;
    }
}